<ng-container *transloco="let translate; read: 'payrollProcess'">
  <div class="k-overlay" *ngIf="opened"></div>
  <div class="row justify-content-between">
    <ng-container *ngIf="external_para !== 1; else other">
      <h4 class="col-sm-6" *ngIf="!wizardMode">{{translate('title')}}</h4>
    </ng-container>
    <ng-template #other>
      <h4 class="col-sm-6" *ngIf="!wizardMode">{{translate('titleAlt')}}</h4>
    </ng-template>
    <div class="col-auto" *ngIf="!wizardMode">
      <app-back-routing-btn [routeTo]="['payrollsetup/PayrollGroupList']" [text]="translate('backToPayrollGroups')"></app-back-routing-btn>
    </div>
  </div>
  <div class="card">
    <div class="card-body">
      <div class="row mb-4">
        <!--Payroll Group-->
        <div class="col-sm-3">
          <div class="row">
            <div class="col-12 mb-2">
              <label for="payroll_group" class="form-label">{{translate('payrollGroup')}}</label>
              <select class="form-select" [disabled]="wizardMode" id="payroll_group" name="payroll_group"
                      (change)="onDropDownChange($event.target.value)">
                <option *ngFor="let item of payrollGroups" [value]="item.a">{{item.b}}</option>
              </select>
            </div>
          </div>
          <ng-container *ngIf="external_para == 1">
            <div class="row">
              <div class="col-12 mb-2">
                <label for="payroll_group" class="form-label">{{translate('paymentTypes')}}</label>
                <kendo-multiselect class="form-control" [data]="payrollCategoryTypes" [textField]="'b'" [valueField]="'a'"
                                   [placeholder]="translate('selectCategories')"
                                   [(ngModel)]="selectedTypes" (valueChange)="onPayCatDropDownChange($event)" style="width:80%"></kendo-multiselect>
              </div>
            </div>
          </ng-container>
        </div>
        <!--Payroll Group end-->
        <!--Process Time-->
        <div class="col-sm-3 mb-2">
          <label class="form-label"><i class="fas fa-calendar"></i> {{processPeriod}}</label>
          <input type="text" class="form-control" [(ngModel)]="payfrequency" disabled id="pfreq"/>
        </div>
        <!--Process Time end-->
        <!--Process button-->
        <div class="col-sm-6 d-flex justify-content-end align-items-end">
          <button type="button" class="btn btn-outline-primary me-2 mb-2" data-bs-toggle="modal" data-bs-target="#employeeNoteModal"
                  [disabled]="processing == true"
                  *ngIf="sysPref?.showEmployeeNotepadFlg">{{translate('employeeNotes')}}
          </button>
          <button type="button" class="btn btn-outline-primary me-2 mb-2" (click)="onOpenButtonClick()"
                  [disabled]="processing == true" *appViewAccessControl="'payrollreports'">{{translate('payrollJournal')}}
          </button>
          <button class="btn btn-primary me-2 mb-2" (click)="onProcessButtonClick()" [disabled]="processing == true">
            <i class="fas fa-hourglass-half"></i>&nbsp;<span transloco="process"></span>
          </button>
          <button class="btn btn-primary mb-2" (click)="onRollbackButtonClick()" [disabled]="processing == true">
            <i class="fas fa-redo-alt"></i>&nbsp;{{translate('rollback')}}
          </button>
        </div>
        <!--Process button end-->
        <div class="col-12 mt-3" *ngIf="value">
          <div class="row justify-content-center">
            <div class="col">
              <progressbar
                style="height: 1rem"
                type="success"
                [max]="100"
                [value]="value"
                [animate]="true"
                [striped]="value < 100"
              >
                {{value | number:'1.0-0'}}%
              </progressbar>
            </div>
            <div class="col-auto" *ngIf="value === 100">
              <i class="fas fa-times cursor-pointer" (click)="value = null"></i>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div [ngClass]="isPayStubActive ? 'col-sm-7' : 'col-sm-12'">
          <div class="row mb-3 justify-content-between">
            <div class="col-sm-5">
              <div class="row">
                <div class="col">
                  <app-search-box iconFontClass="mdi mdi-account-search"
                                  [(ngModel)]="searchText"
                                  placeholder="{{translate('searchEmployeesHere')}}"
                                  (keyup)="searchEmp()"
                  ></app-search-box>
                </div>
                <div class="col-auto">
                  <div class="btn-group" dropdown placement="bottom left" container="body">
                    <button id="button-basic" dropdownToggle type="button" class="btn btn-secondary dropdown-toggle" aria-controls="dropdown-basic">
                      <i class="fas fa-tasks"></i><span class="caret"></span>
                    </button>
                    <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                      <li class="menu-item" (click)="onclickAll()"><span class="dropdown-item" transloco="all"></span></li>
                      <li class="divider dropdown-divider"></li>
                      <li class="menu-item" (click)="onclickProcessed()"><span class="dropdown-item">{{translate('processed')}}</span></li>
                      <li class="menu-item" (click)="onclickUnProcessed()"><span class="dropdown-item">{{translate('pending')}}</span></li>
                      <li class="menu-item" (click)="onclickWithTimesheet(1)"><span class="dropdown-item">{{translate('withTimesheetLabor')}}</span>
                      </li>
                      <li class="menu-item" (click)="onclickWithTimesheet(0)"><span
                        class="dropdown-item">{{translate('withoutTimesheetLabor')}}</span>
                      </li>
                      <li class="menu-item" (click)="onclickWithWarning()"><span class="dropdown-item">{{translate('withWarnings')}}</span></li>
                      <li class="menu-item" (click)="onclickWithErrors()"><span class="dropdown-item">{{translate('withErrors')}}</span></li>
                      <li class="divider dropdown-divider"></li>
                      <li class="menu-item" (click)="onclickClearList()"><span class="dropdown-item" transloco="clearList"></span></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-5 col-sm-7">
              <app-sorting-list
                (sortingChanged)="orderOption=$event"
                [sortingOptions]="sortingOptions" [sortKey]="orderOption.order"
              >
              </app-sorting-list>
            </div>
          </div>
          <div class="row row-cols-1 row-cols-sm-2" [ngClass]="isPayStubActive ? 'row-cols-lg-2' : 'row-cols-lg-4'">
            <ng-container *ngIf="external_para !== 1; else filteredPRActiveEmployeesExternalList">
              <div class="col"
                   *ngFor="let emp of filteredPRActiveEmployees | searchfilter: searchText | orderBy: orderOption.order : orderOption.acc">
                <div class="card border">
                  <div class="card-body p-2 employee-card-height">
                    <div class="row justify-content-between">
                      <div class="col-12 d-flex align-items-center">
                        <div class="form-check" style="width: calc(100% - 70px);">
                          <input type="checkbox" class="form-check-input" (change)="checkBoxClicked($event, emp.b)" id="id{{emp.b}}"
                                 [checked]="(emp.flg==1)">
                          <label for="id{{emp.b}}" class="form-check-label">
                            <span>{{emp.c}}</span><br>
                            <span class="text-muted font-size-11">{{emp.e}}</span>
                          </label>
                        </div>
                        <div class="d-flex justify-content-end align-items-center" style="width: 70px" *ngIf="emp.d !== 0">
                          <span class="me-2 p-1" *ngIf="emp?.Note && sysPref?.showEmployeeNotepadFlg" [tooltip]="(emp?.Note | slice: 0: 500)"
                                (click)="onViewNotes(emp)">
                            <i class="far fa-sticky-note"></i>
                          </span>
                          <span class="badge rounded-pill me-2 p-1" [ngClass]="getClassesForIcon(emp.d, emp.f)">
                            <i class="fas fa-check"></i>
                          </span>
                          <button class="btn btn-outline-secondary btn-sm" (click)="quickViewPayStub(emp.b, emp.c)"
                                  tooltip="{{translate('viewPaycard')}}"
                                  container="body"
                                  *ngIf="emp.d == 1 || emp.d == 2">
                            <i class="far fa-file-alt"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
            <ng-template #filteredPRActiveEmployeesExternalList>
              <div class="col"
                   *ngFor="let emp of filteredPRActiveEmployeesExternal | searchfilter: searchText | orderBy: orderOption.order : orderOption.acc">
                <div class="card border">
                  <div class="card-body p-2 employee-card-height">
                    <div class="row justify-content-between">
                      <div class="col-12 d-flex align-items-center">
                        <div class="form-check float-end">
                          <input type="checkbox" class="form-check-input" (change)="checkBoxClicked($event, emp.b)" id="id{{emp.b}}"
                                 [checked]="(emp.flg==1)">
                          <label for="id{{emp.b}}" class="form-check-label">
                            <span>{{emp.c}}</span><br>
                            <span class="text-muted font-size-11">{{emp.e}}</span>
                          </label>
                        </div>
                      </div>
                      <div class="col-12 d-flex align-items-center justify-content-end" *ngIf="emp.d !== 0">
                        <span class="me-2 p-1" *ngIf="emp?.Note && sysPref?.showEmployeeNotepadFlg" [tooltip]="(emp?.Note | slice: 0: 500)"
                              (click)="onViewNotes(emp)">
                          <i class="far fa-sticky-note"></i>
                        </span>
                        <span class="badge rounded-pill me-2 p-1" [ngClass]="getClassesForIcon(emp.d, emp.f)">
                          <i class="fas fa-check"></i>
                        </span>
                        <button class="btn btn-outline-secondary btn-sm" (click)="quickViewPayStub(emp.b, emp.c)" tooltip="quickview"
                                *ngIf="emp.d == 1 || emp.d == 2">
                          <i class="far fa-file-alt"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </div>
        </div>
        <!--Pay stub-->
        <div class="col-sm-5" *ngIf="isPayStubActive === true">
          <div class="card border">
            <div class="card-body p-3">
              <div class="row justify-content-between">
                <h5 class="col-auto">{{translate('quickView')}}</h5>
                <div class="col-auto">
                  <button class="btn btn-close" (click)="onPaystubCloseButton()"></button>
                </div>
              </div>
              <div class="row">
                <div class="col-12 mb-3">
                  <div class="row">
                    <div class="col-auto">
                      <img src="/assets/images/avatar/avatar-markup.svg" class="img-fluid avatar-sm rounded-circle border" alt="Employee Image"/>
                    </div>
                    <div class="col-9">
                      <div class="row">
                        <div class="col">{{empNo}} - {{empN}}</div>
                      </div>
                      <div class="row">
                        <div class="col"><i class="mdi mdi-calendar-expand-horizontal"></i>{{sdate}} - {{edate}}</div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6"></div>
                    <div class="col-3 text-center">{{translate('current')}}</div>
                    <div class="col-3 text-center">{{translate('ytd')}}</div>
                  </div>
                  <div class="row" *ngFor="let item of paystub" [ngStyle]="{'font-weight': item.h === 1 ? 'bold' : 'normal'}">
                    <div class="col-6">{{item.c}}</div>
                    <div class="col-3 text-right">{{item.d | number:'1.2-2'}}</div>
                    <div class="col-3 text-right">{{item.f | number:'1.2-2'}}</div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 d-flex justify-content-end">
                  <button role="button" class="btn btn-outline-secondary btn-sm me-2" (click)="onPaystubEditClick()" data-bs-toggle="modal"
                          data-bs-target="#paystubEditModal"
                          *ngIf="external_para !== 1">
                    <i class="fas fa-pencil-alt"></i>&nbsp;<span transloco="edit"></span>
                  </button>
                  <ng-container *ngIf="external_para !== 1; else nextcont">
                    <a role="button" class="btn btn-outline-secondary btn-sm me-2"
                       [routerLink]="['/process/processwizard/wizard/PayrollCategoryEntitlementEmployeeWise', empIdClicked, 1, 0]">
                      <i class="fas fa-pencil-alt"></i>&nbsp;{{translate('payrollCategory')}}
                    </a>
                  </ng-container>
                  <ng-template #nextcont>
                    <a role="button" class="btn btn-outline-secondary btn-sm me-2"
                       [routerLink]="['/process/processwizard/wizard/PayrollCategoryEntitlementEmployeeWise', empIdClicked, 1, 1]">
                      <i class="fas fa-pencil-alt"></i>&nbsp;{{translate('payrollCategory')}}
                    </a>
                  </ng-template>
                  <button role="button" class="btn btn-outline-secondary btn-sm" (click)="onCostClick()" *ngIf="is_cost">
                    <i class="fas fa-pencil-alt"></i>&nbsp;{{translate('cost')}}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--Pay stub end-->
    </div>
  </div>

  <!--paystub edit modal starts-->
  <div class="modal fade" id="paystubEditModal" tabindex="-1" role="dialog" aria-labelledby="paystubEditModalLabel" aria-hidden="true"
       data-bs-backdrop="static">
    <div class="modal-dialog modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="onPayeditCloseButton()"></button>
        </div>
        <div class="modal-body">
          <!--header row starts-->
          <div class="row">
            <div class="col-3">
              <img src="/assets/images/avatar/avatar-markup.svg" class="img-fluid avatar-xl float-end border rounded-circle" alt="Employee Image"/>
            </div>
            <div class="col-8">
              <div class="row mb-2">
                <div class="col-12 mb-2"><b>{{empNo}}</b>&nbsp;<b>- {{empN}}</b></div>
                <div class="col-12 mb-2"><b><i class="fas fa-calendar"></i> {{sdate}} - {{edate}}</b></div>
                <div class="col-12 mb-2" *ngIf="sysPref?.y != 82"><b>{{translate('provinceLabel')}}: {{province}}</b></div>
              </div>
              <div class="row mb-2">
                <label for="memotoemployee" class="col-form-label col-sm-auto">{{translate('memoToEmployee')}}</label>
                <div class="col-sm-6">
                  <input class="form-control" maxlength="250" type="text" id="memotoemployee" name="memotoemployee" [(ngModel)]="memo_to_employee">
                </div>
              </div>
            </div>
          </div>
          <!--header row ends-->
          <div class="row mb-2">
            <div class="col-12">
              <table class="table table-sm">
                <thead>
                <tr>
                  <th scope="col"></th>
                  <th scope="col"></th>
                  <th scope="col"><b><span transloco="units"></span></b></th>
                  <th scope="col"><b><span transloco="rate"></span></b></th>
                  <th scope="col"><b><span transloco="amount"></span></b></th>
                  <th scope="col"></th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let item of paycardEditListCopy; let i = index">
                  <td [ngStyle]="{'font-weight': item.h === 1 ? 'bold' : 'normal'}">{{item.z}} {{item.c}}</td>
                  <td>{{item.UNITS_TEXT}}</td>
                  <td [ngClass]="{'td-styles': item.p == 1}">
                    <ng-container *ngIf="item.t == 1">
                      <input class="form-control form-control-sm" type="number" step="0.01"
                             [disabled]="(item.w == 0 ) || (item.w == 2 && item.p != 1)"
                             [value]="item.k"
                             [pattern]="regex2" (input)="onUnitChange($event, i)"/>
                      <ng-container *ngIf="(item.w == 1 || item.w == 2) && item.o == 1 && item.p == 1">
                        <div class="calc-values">[ {{item.q}} ]</div>
                      </ng-container>
                    </ng-container>
                  </td>
                  <td [ngClass]="{'td-styles': item.p == 1}">
                    <ng-container *ngIf="item.u == 1">
                      <input class="form-control form-control-sm" type="number" step="0.01" [disabled]="(item.x == 0) || (item.x == 2 && item.p != 1)"
                             [value]="item.l"
                             [pattern]="regex1" (input)="onRateChange($event, i)"/>
                      <ng-container *ngIf="(item.x == 1 || item.x == 2) && item.o == 1 && item.p == 1">
                        <div class="calc-values">[ {{item.r}} ]</div>
                      </ng-container>
                    </ng-container>
                  </td>
                  <td [ngClass]="{'td-styles': item.p == 1}">
                    <ng-container *ngIf="item.v == 1">
                      <input class="form-control form-control-sm" type="number" step="0.01" [disabled]="(item.y == 0) || (item.y == 2 && item.p != 1)"
                             [value]="item.d"
                             [pattern]="regex" (input)="onAmountChange($event, i)"/>
                      <ng-container *ngIf="(item.y == 1 || item.y == 2) && item.o == 1 && item.p == 1">
                        <div class="calc-values">[ {{item.s}} ]</div>
                      </ng-container>
                    </ng-container>
                  </td>
                  <td>
                    <ng-container *ngIf="item.o == 1">
                      <div class="form-check">
                        <input type="checkbox" name="ovrwrite{{i}}" id="ovrwrite{{i}}" (click)="isOverwriteCheck($event,i)" class="form-check-input"
                               [checked]="item.p == 1" [disabled]="item.OVERWRITE_EDITABLE!=1"/>
                        <label for="ovrwrite{{i}}" class="label-text"><span transloco="overwrite"></span></label>
                      </div>
                    </ng-container>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="row justify-content-end">
            <div class="col-auto">
              <button class="btn btn-primary" (click)="onEditPaycardProcessClick()">
                <i class="fas fa-hourglass-half"></i>&nbsp;<span transloco="process"></span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--paystub edit modal ends-->
  <!--Modal-->
  <kendo-window id="reportModal" class="kwindow-center" *ngIf="opened" (close)="close()" [minWidth]="250" [width]="1000">
    <kendo-window-titlebar>
      <div class='k-window-title'></div>
      <button kendoWindowCloseAction></button>
    </kendo-window-titlebar>
    <ng-container *ngIf="rprtSrce" style="overflow:auto">
      <tr-viewer #viewer1
                 [containerStyle]="viewerContainerStyle"
                 [serviceUrl]="baseURL"
                 [reportSource]="reportSource"
                 [viewMode]="'INTERACTIVE'"
                 [scaleMode]="'FIT_PAGE_WIDTH'"
                 [scale]="1.0"
                 [sendEmail]="{enabled: true}"
                 [authenticationToken]="token">
      </tr-viewer>
    </ng-container>
  </kendo-window>
  <!--Modal ends-->
  <!--cost modal-->
  <app-cost-modal [sysPref]="sysPref" [employeeId]="employeeId" [empNo]="empNo" [empN]="empN" [sdate]="sdate" [edate]="edate"
                  [payrollGrpId]="payrollGrpId"
                  [filteredPRActiveEmployeesList]="filteredPRActiveEmployeesList" [prProcessYear]="prProcessYear"
                  [prProcessStartdate]="prProcessStartdate"
                  [prProcessEndtdate]="prProcessEndtdate" [proc_seq_number]="proc_seq_number" [sessionId]="sessionId" [connectionId]="connection?.id"
                  [costData]="costData"
                  [laborTimesheetList]="laborTimesheetList" (sessionid_cost)="getCostSessionId($event)"></app-cost-modal>

  <!--  <app-process-output-window [title]="translate('outputCurrentProcessPeriod') + ' ['+ processPeriod + ']'" *ngIf="processingDone" (close)="processingDone = false">-->
  <!--    <div class="row mb-1">-->
  <!--      <div class="col-auto">-->
  <!--        <span class="badge bg-success rounded-pill"><i class="fas fa-check"></i> {{processSummary?.output?.success}}</span>&nbsp;<span-->
  <!--        transloco="success"></span>-->
  <!--      </div>-->
  <!--      <div class="col-auto">-->
  <!--        <span class="badge bg-danger rounded-pill"><i class="fas fa-exclamation"></i> {{processSummary?.output?.error}}</span>&nbsp;<span-->
  <!--        transloco="error"></span>-->
  <!--      </div>-->
  <!--      <div class="col-auto">-->
  <!--        <span class="badge bg-warning rounded-pill"><i class="fas fa-exclamation-triangle"></i> {{processSummary?.output?.warning}}</span>&nbsp;<span-->
  <!--        transloco="warning"></span>-->
  <!--      </div>-->
  <!--      <div class="col-auto">-->
  <!--        <span class="badge bg-info rounded-pill"><i class="fas fa-info"></i> {{processSummary?.output?.message}}</span>&nbsp;{{translate('message')}}-->
  <!--      </div>-->
  <!--    </div>-->
  <!--    <div class="row">-->
  <!--      <div class="col-12" *ngFor="let item of processSummary?.messages">-->
  <!--        <ng-container [ngSwitch]="item.a">-->
  <!--          <span class="badge bg-success rounded-pill" *ngSwitchCase="1"><i class="fas fa-check"></i></span>-->
  <!--          <span class="badge bg-warning rounded-pill" *ngSwitchCase="2"><i class="fas fa-exclamation-triangle"></i></span>-->
  <!--          <span class="badge bg-danger rounded-pill" *ngSwitchCase="3"><i class="fas fa-exclamation"></i></span>-->
  <!--          <span class="badge bg-info rounded-pill" *ngSwitchCase="4"><i class="fas fa-info"></i></span>-->
  <!--        </ng-container>-->
  <!--        {{item.b}} - {{item.d}}-->
  <!--      </div>-->
  <!--    </div>-->
  <!--  </app-process-output-window>-->

  <div class="modal fade" id="selectedEmployeeNoteModal" tabindex="-1" role="dialog" aria-labelledby="selectedEmployeeNoteModalLabel"
       aria-hidden="true"
       data-bs-backdrop="static">
    <div class="modal-dialog modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{selectedEmployee?.e}} - {{selectedEmployee?.c}}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          {{selectedEmployee?.Note}}
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="employeeNoteModal" tabindex="-1" role="dialog" aria-labelledby="employeeNoteModalLabel" aria-hidden="true"
       data-bs-backdrop="static">
    <div class="modal-dialog modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{translate('employeeNotes')}}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="table-responsive">
            <table class="table">
              <thead>
              <tr>
                <th scope="col">{{translate('employeeNumber')}}</th>
                <th scope="col">{{translate('employeeName')}}</th>
                <th scope="col">{{translate('note')}}</th>
              </tr>
              </thead>
              <tbody>
              <ng-container *ngFor="let emp of filteredPRActiveEmployees | searchfilter:searchText">
                <tr *ngIf="emp?.Note">
                  <td>{{emp?.e}}</td>
                  <td>{{emp?.c}}</td>
                  <td>{{emp?.Note}}</td>
                </tr>
              </ng-container>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
