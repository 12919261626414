const payrollGroup = 'PayrollGroup/';
const payrollCategory = 'PayrollCategory/';
const paycard = 'Paycard/';
const bank = 'Bank/';
const udf = 'UDF/';
const gl = 'GL/';
const jobcost = 'JobCost/';
const backup = 'Backup/';
const account = 'Account/';
const calendar = 'Calendar/';
const chequePrinting = 'ChequePrinting/';
const company = 'Company/';
const dataExport = 'DataExport/';
const department = 'Department/';
const email = 'Email/';
const employee = 'Employee/';
const ess = 'ESS/';
const global = 'Global/';
const location = 'Location/';
const restore = 'Restore/';
const rl1 = 'RL1/';
const roe = 'Roe/';
const systemPreference = 'SystemPreference/';
const t4form = 'T4form/';
const taxFormsCAN2020 = 'TaxFormsCAN2020/';
const taxFormsCAN2021 = 'TaxFormsCAN2021/';
const taxFormsCAN2022 = 'TaxFormsCAN2022/';
const taxFormsCAN2023 = 'TaxFormsCAN2023/';
const taxFormsCAN2024 = 'TaxFormsCAN2024/';
const usForm = 'TaxForms/';
const user = 'User/';
const yearEnd = 'YearEnd/';
const wizard = 'Wizard/';
const reportnavigator = 'ReportNavigator/';
const reportviewer = 'ReportImportViewer/';
const labor = 'Labor/';


export const API_END_POINT = {
  payroll: {
    payrollGroup: payrollGroup,
    payrollCategory: payrollCategory,
    paycard: paycard,
    bank: bank,
    udf: udf,
    gl: gl,
    jobcost: jobcost,
    backup: backup,
    chequePrinting: chequePrinting,
    dataExport: dataExport,
    employee: employee,
    restore: restore,
    roe: roe,
    systemPreference: systemPreference,
    t4form: t4form,
    taxFormsCAN2020: taxFormsCAN2020,
    taxFormsCAN2021: taxFormsCAN2021,
    taxFormsCAN2022: taxFormsCAN2022,
    taxFormsCAN2023: taxFormsCAN2023,
    taxFormsCAN2024: taxFormsCAN2024,
    usForm: usForm,
    yearEnd: yearEnd,
    reportnavigator: reportnavigator,
    reportviewer: reportviewer,
    wizard: wizard,
    labor: labor
  },
  account: {
    account: account,
    user: user,
    global: global
  },
  core: {
    calendar: calendar,
    company: company,
    department: department,
    email: email,
    location: location
  },
  ess: {
    ess: ess
  }
};

