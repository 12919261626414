<ng-container *transloco="let translate; read: 'sortingList'">
  <div class="row justify-content-center">
    <div class="col-auto mb-2">
      <div class="row">
        <label for="payroll_group" class="col-sm-auto col-form-label">{{translate('orderBy')}}</label>
        <div class="col-auto">
          <select class="form-select" id="payroll_group" name="payroll_group" [ngModel]="sortKey" (ngModelChange)="sortingKeyOnChanged($event)">
            <option *ngFor="let item of sortingOptions" [value]="item.value">{{item.name}}</option>
          </select>
        </div>
      </div>
    </div>
    <div class="col-auto d-flex align-items-center mb-2">
      <div class="form-check form-check-inline form-radio-secondary" *ngFor="let obj of reverseOptions; let i=index">
        <input class="form-check-input"
               type="radio"
               name="sortKey"
               [id]="'sortKey' + i" [value]="obj.value"
               (ngModelChange)="sortingAccOnChanged($event)"
               [ngModel]="sortAcc">
        <label class="form-check-label" [for]="'sortKey' + i">{{obj?.name}}</label>
      </div>
    </div>
  </div>
</ng-container>
