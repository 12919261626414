import {AfterViewInit, Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {PaycardServiceService} from '@harmony-modules/payroll/services/admin-setup/paycard-service.service';
import {ILocString} from '@shared/models/iLocalization';
import {IPaycardImportResult,IPaycardImportParam, IPaycard} from '@shared/models/ipaycard';
import {IPayrollProcessPeriod} from '@shared/models/ipayroll-group';
import {TRANSLOCO_SCOPE, TranslocoService} from '@ngneat/transloco';
import {ShowMessageService} from '@services/show-message.service';
import {scopeLoader} from '@shared/i18n/transloco-scoped-loader.helper.';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

const scope = 'importPaycard';

declare var $: any;
declare var showMsg: any;

@UntilDestroy()
@Component({
  selector: 'app-import-paycard',
  templateUrl: './import-paycard.component.html',
  styleUrls: ['./import-paycard.component.scss'],
  providers:
    [{
      provide: TRANSLOCO_SCOPE,
      useValue: {scope: 'importPaycard', loader: scopeLoader((lang, root) => import(`./${root}/${lang}.json`))}
    }]
})
export class ImportPaycardComponent implements OnInit, AfterViewInit {
  @ViewChild('f') form: NgForm;

  interface: IPaycard;
  interfaceID: number;
  uploadedFile: File = null;
  fileName: string = '';
  fileContent: any = '';
  action: number; // 0: Start, 1: overwrite, 2: skip, 3: Cancel
  resultErrors: IPaycardImportResult[];
  status: number = -1;
  sessionID: string = '';
  paycardList: IPaycard[] = null;
  is_direct: boolean = false;
  //onlyApprovedTimesheet: boolean = false;
  fileContentWithParams: IPaycardImportParam = {
    sessionID: null, 
    fileContent: null,
    interfaceID: null,
    seqNo: null,
    fromDate: null,
    toDate: null,
    payrollGroupID: null,
    year: null,
    action: null,
    approved: null
  };

  @Input() payrollPeriodBase: IPayrollProcessPeriod;
  @Input() payrollGroupID: number;
  @Output() updateList = new EventEmitter();
  @Output() formLoaded = new EventEmitter<NgForm>();

  constructor(
    private _paycardService: PaycardServiceService,
    @Inject(TRANSLOCO_SCOPE) private scope,
    private transloco: TranslocoService,
    private showMessage: ShowMessageService) {
  }

  ngOnInit() {
    this._paycardService.getPaycardInterfaceList().pipe(untilDestroyed(this)).subscribe(
      data => {
        if (data) {
          this.paycardList = data.filter(x => x.c == 1);
        //  this.interface = this.paycardList.find(x => x.h == 1);
        //  this.interfaceID = this.interface.a;
        //  this.is_direct = this.interface.j == 1 ? true : false;
        }
      });
  }

  onSelectedChange() {
    this.interface = this.paycardList.find(x => x.a == this.interfaceID);
    this.is_direct = this.interface.j == 1 ? true : false;
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.formLoaded.emit(this.form);
    }, 100);
  }

  onSubmit(form: NgForm) {
    this.action = 0; // start
    this.processFile('');
  }

  processFile(s: string) {
    let p: number = 0;
    //if (this.onlyApprovedTimesheet) {
    //always import approved timesheet
      p = 1;
    //}
    this.fileContentWithParams.sessionID = s;
    this.fileContentWithParams.fileContent = this.fileContent;
    this.fileContentWithParams.interfaceID = this.interfaceID;
    this.fileContentWithParams.seqNo = 0; //this.payrollPeriodBase.f;
    this.fileContentWithParams.fromDate = this.payrollPeriodBase.b;
    this.fileContentWithParams.toDate = this.payrollPeriodBase.c;
    this.fileContentWithParams.payrollGroupID = this.payrollGroupID;
    this.fileContentWithParams.year = this.payrollPeriodBase.e;
    this.fileContentWithParams.action = this.action;
    this.fileContentWithParams.approved = p;


    this._paycardService.processImportPaycard(this.fileContentWithParams).pipe(untilDestroyed(this)).subscribe(
      data => {
        this.resultErrors = data;
          if (this.resultErrors != null && this.resultErrors != undefined && this.resultErrors.length > 0) {
            const errorKeys = Object.keys((this.resultErrors[0].o as unknown as ILocString).List);
            if (errorKeys[0] == 'alerts.bc.noTimesheetToImport') {
              $('#ImportPaycardModal').modal('hide');
              $('body').removeClass('modal-open');
              $('.modal-backdrop').remove();
              this.updateList.emit();
              this.status = this.resultErrors[0].s;
              const title = this.transloco.translate('error');
              let message = this.showMessage.translateErrorMessage(data[0]?.o);
                showMsg({
                  'type': 'nonsticky',
                  'status': 'error',
                  'title': title,
                  'buttons': {},
                  'message': message,
                  'delay': '1500'
                });
            }
            else {
              this.resultErrors.forEach((element) => {
                element.translatedError = this.showMessage.translateErrorMessage(element.o as unknown as ILocString);
              });
              this.status = this.resultErrors[0].s;
              this.sessionID = this.resultErrors[0].b;
              this.modalToggle();
            }
          }
          else {
          this.status = -1;
        }

        if (this.status >= 2 || this.status < 0) {
          $('#ImportPaycardModal').modal('hide');
          $('body').removeClass('modal-open');
          $('.modal-backdrop').remove();
          this.updateList.emit();
          const title = this.transloco.translate('success');
          let message = this.transloco.translate('alerts.successfullyImported', {}, 'importPaycard');
          message += this.showMessage.translateErrorMessage(data[0]?.o);
          if (this.status < 0) {
            showMsg({
              'type': 'nonsticky',
              'status': 'success',
              'title': title,
              'buttons': {},
              'message': message,
              'delay': '1500'
            });
          }
        }
      });
  }

  onFileSelected(event: any) {
    if (event.target.files && event.target.files[0]) {
      this.uploadedFile = event.target.files[0];
      this.fileName = this.uploadedFile.name;
      var reader = new FileReader();
      reader.onloadend = (event: any) => {
        this.fileContent = reader.result;// event.target.result;
        this.action = 0;
      };
      reader.readAsText(this.uploadedFile);
    }
  }

  modalToggle(): void {
    //$('#errorModal').modal({
    //  'toggle': 'true'
    //});
    $('#errorModal').modal('show');
  }

  onClickModal(a: any) {
    this.action = a; // Skip or Overwrite
    if (this.action != 3) {
      this.processFile(this.sessionID);
    }
  }

}
