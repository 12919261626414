import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BehaviorSubject, forkJoin, Observable} from 'rxjs';
import {
  EmployeeTaxForms,
  IEmployee,
  IEmployeeStatesUpdate,
  IEmployeeUpdate,
  INumofEmployee,
  PayrollEmployeeList,
  PayrollEmployeeListFilter
} from 'src/app/shared/models/iemployee';
import {IDimensionForGlSetup} from 'src/app/shared/models/igl';
import {
  IDentalBenefitOption,
  IEmpInfoOtherGet,
  IEmployeeDentalBenefit,
  IFederalTaxWrite,
  IProvinceList,
  ITaxProvinceGet,
  ITaxWrite_NS,
  ITaxWrite_QC,
  ITaxWrite_SK,
  IUDFCollection,
  IUSFederalTaxWrite,
  IUSStateTaxWrite
} from 'src/app/shared/models/ipayroll-category';
import {
  IBankDetailsClear,
  IPBank,
  IPRBank,
  IPRBankBranch,
  IUpdateBankBranch,
  IUpdateBankBranchReturn,
  PMEmpBank,
  PMEmpBankDelete,
  PMEmpBankUpdate
} from 'src/app/shared/models/ipbank';
import {IPMGroup} from 'src/app/shared/models/ipmgroup';
import {ISysUdfWithValue} from 'src/app/shared/models/iudf';
import {API_END_POINT} from 'src/app/shared/utils/api-end-points';
import {ILaborSetup, IPayrollGroupsLabor} from '../../../../../shared/models/ilabor';


@Injectable({
  providedIn: 'root'
})
export class EmployeeListService {

  private empListSource = new BehaviorSubject<IEmployee[]>(null);
  currentEmpList = this.empListSource.asObservable();

  private payrollFreqSource = new BehaviorSubject<IPMGroup[]>(null);
  currentFreqList = this.payrollFreqSource.asObservable();

  private bankAccDetails = new BehaviorSubject<IPBank[]>(null);
  currentBankAccDetails = this.bankAccDetails.asObservable();

  private bankList = new BehaviorSubject<IPRBank[]>(null);
  currentBankList = this.bankList.asObservable();

  private branchList = new BehaviorSubject<IPRBankBranch[]>(null);
  currentBranchList = this.branchList.asObservable();

  private dimensionList = new BehaviorSubject<IDimensionForGlSetup[]>(null);
  currentDimensionList = this.dimensionList.asObservable();

  constructor(private http: HttpClient) {
  }

  resetData() {
    this.empListSource.next(null);
    this.payrollFreqSource.next(null);
    this.bankAccDetails.next(null);
    this.bankList.next(null);
    this.branchList.next(null);
    this.dimensionList.next(null);
  }

  changeDimensionList(dimList: IDimensionForGlSetup[]) {
    this.dimensionList.next(dimList);
  }


  GetPayrollGroupsForLaborSetup(): Observable<IPayrollGroupsLabor[]> {
    return this.http.get<IPayrollGroupsLabor[]>(API_END_POINT.payroll.employee + 'GetPayrollGroupsForLaborSetup');
  }
  GetDimensionsForGlSetup(): Observable<IDimensionForGlSetup[]> {
    return this.http.get<IDimensionForGlSetup[]>(API_END_POINT.payroll.employee + 'GetDimensionsForGlSetup');
  }

  getEmployeeList(is_us: boolean): Observable<IEmployee[]> {
    return this.http.get<IEmployee[]>(API_END_POINT.payroll.employee + 'GetEmployeeList?is_us=' + is_us);
  }

  getEmployeeListForAssign(is_us: boolean): Observable<IEmployee[]> {
    return this.http.get<IEmployee[]>(API_END_POINT.payroll.employee + 'GetEmployeeListForAssign?is_us=' + is_us);
  }
  GetLaborSetup(): Observable<ILaborSetup[]> {
    return this.http.get<ILaborSetup[]>(API_END_POINT.payroll.employee + 'GetLaborSetupForEmpList');
  }

  getEmployeeListPaged(filters: PayrollEmployeeListFilter): Observable<PayrollEmployeeList> {
    return this.http.get<PayrollEmployeeList>(API_END_POINT.payroll.employee + 'GetEmployeeListPaging_V1', {params: {...filters}});
  }

  getPayrollFrequencyList(): Observable<IPMGroup[]> {
    return this.http.get<IPMGroup[]>(API_END_POINT.payroll.payrollGroup + 'GetPayrollGroupPayFrequencies');
  }

  getTaxProvinces(): Observable<IProvinceList[]> {
    // return this.http.get<IProvince[]>("../api/PayrollGroup/GetPayrollTaxProvince");
    return this.http.get<IProvinceList[]>(API_END_POINT.payroll.payrollCategory + 'GetprovinceForPayrollCategory');
  }

  getEmployeeStates(i: number): Observable<IProvinceList[]> {
    return this.http.get<IProvinceList[]>(API_END_POINT.payroll.employee + 'GetEmployeeStates?i=' + i);
  }

  getUDFCollections(gc: number): Observable<IUDFCollection[]> {
    return this.http.get<IUDFCollection[]>(API_END_POINT.payroll.employee + 'GetUDFCollections?gc=' + gc);
  }


  getFilteredEmployeeList(list: IEmployee[]): IEmployee[] {
    let arr;
    arr = list.map(({ i, j, n, a, l, u, f, st, province }) => ({ i, j, n, a, l, u, f, st, province }));
    return arr;
  }

  getUSStatesUdf(gc: string, ie: number): Observable<ISysUdfWithValue[]> {
    const para = new HttpParams().set('gc', gc).set('ie', String(ie));
    return this.http.get<ISysUdfWithValue[]>(API_END_POINT.payroll.employee + 'GetUSStatesUdf', { params: para });
  }

  getEmployeeFormatedName(id: number): Observable<string> {
    return this.http.get<string>(API_END_POINT.payroll.employee + 'GetEmployeeFormatedName?id=' + id);
  }

  changeEmpList(emplist: IEmployee[]) {
    this.empListSource.next(emplist);
  }

  changeFreqList(frqList: IPMGroup[]) {
    this.payrollFreqSource.next(frqList);
  }

  changeBankAccDetails(accList: IPBank[]) {
    this.bankAccDetails.next(accList);
  }

  updatePayrollEmployee(employee: IEmployeeUpdate): Observable<any> {
    return this.http.post<void>(API_END_POINT.payroll.employee + 'UpdatePayrollEmployee', employee);
  }

  updateEmployeeStates(e: IEmployeeStatesUpdate): Observable<any> {
    return this.http.post<void>(API_END_POINT.payroll.employee + 'UpdateEmployeeStates', e);
  }

  getEmployeeBankAccDetails(id: number): Observable<IPBank[]> {
    const para = new HttpParams().set('id', String(id));
    return this.http.get<IPBank[]>(API_END_POINT.payroll.employee + 'GetEmployeeBankAccDetails', { params: para });
  }

  getBankDetails(): Observable<IPRBank[]> {
    return this.http.get<IPRBank[]>(API_END_POINT.payroll.employee + 'GetBankDetails');
  }

  getBankBranches(): Observable<IPRBankBranch[]> {
    return this.http.get<IPRBankBranch[]>(API_END_POINT.payroll.employee + 'GetBankBranches');
  }

  getDentalBenefitOptions(currentYear: number): Observable<IDentalBenefitOption[]> {
    const para = new HttpParams().set('currentYear', String(currentYear));
    return this.http.get<IDentalBenefitOption[]>(API_END_POINT.payroll.employee + 'GetDentalBenefitOptions', { params: para });
  }

  updateDentalBenefit(currentYear: number, empId: number, selectedDentalBenefit: number): Observable<any> {
    if (!selectedDentalBenefit) selectedDentalBenefit = 0;
    const para: any = { currentYear: currentYear, empId: empId, selectedDentalBenefit: selectedDentalBenefit };
    return this.http.post<void>(API_END_POINT.payroll.employee + 'UpdateDentalBenefit', para);
  }

  getEmployeeDentalBenefit(currentYear: number, empId: number): Observable<IEmployeeDentalBenefit> {
    const para = new HttpParams().set('currentYear', currentYear).set('empId', String(empId));
    return this.http.get<IEmployeeDentalBenefit>(API_END_POINT.payroll.employee + 'GetEmployeeDentalBenefit', { params: para });
  }

  changeBankList(banklist: IPRBank[]) {
    this.bankList.next(banklist);
  }

  changeBankBranchList(branchlist: IPRBankBranch[]) {
    this.branchList.next(branchlist);
  }

  insertEmployeeBankAccDetails(bankDetails: PMEmpBank): Observable<any> {
    return this.http.post<void>(API_END_POINT.payroll.employee + 'InsertEmployeeBankAccDetails', bankDetails);
  }

  updateEmployeeBankAccDetails(bankDetails: PMEmpBankUpdate): Observable<any> {
    return this.http.post<void>(API_END_POINT.payroll.employee + 'UpdateEmployeeBankAccDetails', bankDetails);
  }

  deleteEmployeeBankAccDetails(bnkDetails: PMEmpBankDelete): Observable<any> {
    return this.http.post<void>(API_END_POINT.payroll.employee + 'RemoveEmployeeBankAccDetails', bnkDetails);
  }

  insertNewBranch(ub: IUpdateBankBranch): Observable<IUpdateBankBranchReturn> {

    // let para = new HttpParams().set("bnk_code", String(bnk_code)).set("brnch_usercode", String(brnch_usercode));
    return this.http.post<IUpdateBankBranchReturn>(API_END_POINT.payroll.employee + 'InsertNewBranch', ub);
  }

  updateBankDetailsPercentageFlag(obj: IBankDetailsClear): Observable<any> {

    return this.http.post<void>(API_END_POINT.payroll.employee + 'updateBankDetailsPercentageFlag', obj);
  }

  getTaxProvince(empid: number, provid: number): Observable<ITaxProvinceGet[]> {
    const obj = { empid: empid, provid: provid };
    return this.http.get<ITaxProvinceGet[]>(API_END_POINT.payroll.payrollCategory + 'GetTaxProvince?empid=' + empid + '&provid=' + provid);
  }

  writeFederalTax(payload: IFederalTaxWrite[]): Observable<any> {
    const data = payload.map(o => ({
      fc1: o.fc1,
      fc2: o.fc2,
      fc3: o.fc3,
      fc4: o.fc4,
      fc6: o.fc6,
      fc7: o.fc7,
      fc8: o.fc8,
      fc9: o.fc9,
      fc10: o.fc10,
      fc11: o.fc11,
      fc12: o.fc12,
      fc14: o.fc14,
      fc15: o.fc15,
      fc16: o.fc16,
      fc18: o.fc18,
      fc19: o.fc19,
      fc21: o.fc21,
      fc24: o.fc24,
      fc48: o.fc48,
      fc49: o.fc49,
      fc50: o.fc50,
      fc51: o.fc51,
      fc52: o.fc52,
      fc53: o.fc53,
      empid: o.empid,
      fv1: o.fv1,
      fv2: o.fv2,
      fv3: o.fv3,
      fv4: o.fv4,
      fv6: o.fv6,
      fv7: o.fv7,
      fv8: o.fv8,
      fv9: o.fv9,
      fv10: o.fv10,
      fv11: o.fv11,
      fv12: o.fv12,
      fv14: o.fv14,
      fv15: o.fv15,
      fv16: o.fv16,
      fv18: o.fv18,
      fv19: o.fv19,
      fv21: o.fv21,
      fv24: o.fv24,
      fv48: o.fv48,
      fv49: o.fv49,
      fv50: o.fv50,
      fv51: o.fv51,
      fv52: o.fv52,
      fv53: o.fv53,
      gc: o.gc
    } as any));

    // const obj = {
    //   fc1: o.fc1,
    //   fc2: o.fc2,
    //   fc3: o.fc3,
    //   fc4: o.fc4,
    //   fc6: o.fc6,
    //   fc7: o.fc7,
    //   fc8: o.fc8,
    //   fc9: o.fc9,
    //   fc10: o.fc10,
    //   fc11: o.fc11,
    //   fc12: o.fc12,
    //   fc14: o.fc14,
    //   fc15: o.fc15,
    //   fc16: o.fc16,
    //   fc18: o.fc18,
    //   fc19: o.fc19,
    //   fc21: o.fc21,
    //   fc24: o.fc24,
    //   fc48: o.fc48,
    //   fc49: o.fc49,
    //   fc50: o.fc50,
    //   fc51: o.fc51,
    //   fc52: o.fc52,
    //   fc53: o.fc53,
    //   empid: o.empid,
    //   fv1: o.fv1,
    //   fv2: o.fv2,
    //   fv3: o.fv3,
    //   fv4: o.fv4,
    //   fv6: o.fv6,
    //   fv7: o.fv7,
    //   fv8: o.fv8,
    //   fv9: o.fv9,
    //   fv10: o.fv10,
    //   fv11: o.fv11,
    //   fv12: o.fv12,
    //   fv14: o.fv14,
    //   fv15: o.fv15,
    //   fv16: o.fv16,
    //   fv18: o.fv18,
    //   fv19: o.fv19,
    //   fv21: o.fv21,
    //   fv24: o.fv24,
    //   fv48: o.fv48,
    //   fv49: o.fv49,
    //   fv50: o.fv50,
    //   fv51: o.fv51,
    //   fv52: o.fv52,
    //   fv53: o.fv53,
    //   gc: o.gc
    // };
    return this.http.post<any>(API_END_POINT.payroll.payrollCategory + 'WriteFederalTax', data);
  }

  writeUSFederalTax(o: IUSFederalTaxWrite): Observable<any> {
    const obj = {
      empid: o.empid, gc: o.gc,
      fc1: o.fc1, fc2: o.fc2, fc3: o.fc3, fc4: o.fc4, fc5: o.fc5, fc6: o.fc6, fc7: o.fc7, fc8: o.fc8, fc9: o.fc9, fc10: o.fc10, fc11: o.fc11, fc12: o.fc12, fc13: o.fc13, fc14: o.fc14,
      fv1: o.fv1, fv2: o.fv2, fv3: o.fv3, fv4: o.fv4, fv5: o.fv5, fv6: o.fv6, fv7: o.fv7, fv8: o.fv8, fv9: o.fv9, fv10: o.fv10, fv11: o.fv11, fv12: o.fv12, fv13: o.fv13, fv14: o.fv14
    };
    return this.http.post<any>(API_END_POINT.payroll.payrollCategory + 'WriteUSFederalTax', obj);
  }

  writeUSStatesTax(o: IUSStateTaxWrite[]): Observable<any> {
    return this.http.post<any>(API_END_POINT.payroll.payrollCategory + 'WriteUSStatesTax', o);
  }

  writeTax_NS(o: ITaxWrite_NS): Observable<any> {
    const obj = {
      fc1: o?.fc1, fc2: o?.fc2, fc3: o?.fc3, fc4: o?.fc4, fc6: o?.fc6, fc7: o?.fc7, fc8: o?.fc8, fc9: o?.fc9, fc10: o?.fc10, fc11: o?.fc11,
      fc12: o?.fc12, fc14: o?.fc14, fc15: o?.fc15, fc16: o?.fc16, fc21: o?.fc21, fc24: o?.fc24, fc48: o?.fc48, fc49: o?.fc49,
      fc50: o?.fc50, fc51: o?.fc51, fc52: o?.fc52, fc53: o?.fc53, fc28: o?.fc28, fc45: o?.fc45, fc46: o?.fc46, // 'fc47':o?.fc47,
      empid: o?.empid,
      fv1: o?.fv1, fv2: o?.fv2, fv3: o?.fv3, fv4: o?.fv4, fv6: o?.fv6, fv7: o?.fv7, fv8: o?.fv8, fv9: o?.fv9, fv10: o?.fv10, fv11: o?.fv11, fv12: o?.fv12,
      fv14: o?.fv14, fv15: o?.fv15, fv16: o?.fv16, fv21: o?.fv21, fv24: o?.fv24, fv48: o?.fv48, fv49: o?.fv49, fv50: o?.fv50, fv51: o?.fv51, fv52: o?.fv52, fv53: o?.fv53, fv28: o?.fv28, fv45: o?.fv45, fv46: o?.fv46, // 'fv47': o?.fv47,
      gc: o?.gc
    };
    return this.http.post<any>(API_END_POINT.payroll.payrollCategory + 'writeTaxNS', obj);
  }

  writeTax_QC(o: ITaxWrite_QC): Observable<any> {
    const obj = {
      fc1: o?.fc1, fc2: o?.fc2, fc3: o?.fc3, fc4: o?.fc4, fc21: o?.fc21, fc40: o?.fc40, fc41: o?.fc41, fc42: o?.fc42, fc43: o?.fc43, fc44: o?.fc44, fc149: o?.fc149,
      empid: o?.empid,
      fv1: o?.fv1, fv2: o?.fv2, fv3: o?.fv3, fv4: o?.fv4, fv21: o?.fv21, fv40: o?.fv40, fv41: o?.fv41, fv42: o?.fv42, fv43: o?.fv43, fv44: o?.fv44, fv149: o?.fv149,
      gc: o?.gc
    };
    return this.http.post<any>(API_END_POINT.payroll.payrollCategory + 'writeTaxQC', obj);
  }

  writeTax_SK(o: ITaxWrite_SK): Observable<any> {
    const obj = {
      fc1: o?.fc1, fc2: o?.fc2, fc3: o?.fc3, fc4: o?.fc4, fc6: o?.fc6, fc7: o?.fc7, fc10: o?.fc10, fc11: o?.fc11,
      fc12: o?.fc12, fc14: o?.fc14, fc15: o?.fc15, fc16: o?.fc16, fc21: o?.fc21, fc24: o?.fc24, fc31: o?.fc31, fc48: o?.fc48, fc49: o?.fc49,
      fc52: o?.fc52, fc53: o?.fc53,
      empid: o?.empid,
      fv1: o?.fv1, fv2: o?.fv2, fv3: o?.fv3, fv4: o?.fv4, fv6: o?.fv6, fv7: o?.fv7, fv10: o?.fv10, fv11: o?.fv11, fv12: o?.fv12,
      fv14: o?.fv14, fv15: o?.fv15, fv16: o?.fv16, fv21: o?.fv21, fv24: o?.fv24, fv31: o?.fv31, fv48: o?.fv48, fv49: o?.fv49, fv52: o?.fv52, fv53: o?.fv53,
      gc: o?.gc
    };
    return this.http.post<any>(API_END_POINT.payroll.payrollCategory + 'writeTaxSK', obj);
  }

  getEmpInfoOther(id: number, gc: number, prov: number): Observable<IEmpInfoOtherGet[]> {
    return this.http.get<IEmpInfoOtherGet[]>(API_END_POINT.payroll.payrollCategory + 'GetEmpInfoOther?id=' + id + '&gc=' + gc + '&prov=' + prov);
  }

  CheckEmployeeUnlockedPaycards(emp_id: number): Observable<number> {
    return this.http.get<number>(API_END_POINT.payroll.employee + 'CheckEmployeeUnlockedPaycards?emp_id=' + emp_id);
  }
  CheckEmployeeUnlockedPaycardsForAss(emp_id: number): Observable<number> {
    return this.http.get<number>(API_END_POINT.payroll.employee + 'CheckEmployeeUnlockedPaycardsForAss?emp_id=' + emp_id);
  }
  CheckEmployeeUnassignedTimesheets(emp_id: number): Observable<number> {
    return this.http.get<number>(API_END_POINT.payroll.employee + 'CheckEmployeeUnassignedTimesheets?emp_id=' + emp_id);
  }
  EmployeeValidToSave(emp_id: number): Observable<any> {
    const res1 = this.CheckEmployeeUnlockedPaycards(emp_id);
    const res2 = this.CheckEmployeeUnassignedTimesheets(emp_id);
    const res3 = this.CheckEmployeePaycatsAssigned(emp_id);
    return forkJoin([res1, res2, res3]);
  }

  CheckEmployeePaycatsAssigned(emp_id: number): Observable<number> {
    return this.http.get<number>(API_END_POINT.payroll.employee + 'CheckEmployeePaycatsAssigned?emp_id=' + emp_id);
  }

  getNumberofEmployees(): Observable<INumofEmployee> {
    return this.http.get<INumofEmployee>(API_END_POINT.payroll.employee + 'GetNumberofEmployees');
  }
  getNumberofEmployees_V1(): Observable<INumofEmployee> {
    return this.http.get<INumofEmployee>(API_END_POINT.payroll.employee + 'GetNumberofEmployees_V1');
  }

  getTaxForms(employeeId: number): Observable<EmployeeTaxForms[]> {
    return this.http.get<EmployeeTaxForms[]>(API_END_POINT.payroll.employee + 'GetTaxForms', {params: {employeeId}});
  }
}
